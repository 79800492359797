<template>
  <section id="qualifications">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2><span :class="(data.global['headerIcons']) ? 'pr-3' : 'pr-0'"><b-icon v-if="data.global['headerIcons']" icon="shield-check" class="text-primary" aria-hidden="true"></b-icon></span>{{ data.json.title }} </h2>
        </div>
        <div :class="data.json.responsive['paragraph1']">
          <p v-html="data.json['paragraph1']"></p>
        </div>
        <div :class="data.json.responsive['carousel']">
          <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="4000"
              controls
              indicators
              background="#ababab"
              img-width="1024"
              img-height="480"
              style="text-shadow: 1px 1px 2px #333;"
          >
            <b-carousel-slide :img-src="require('../assets/associations/iacp.jpg')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../assets/associations/napcp.jpg')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../assets/associations/kaof.jpg')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../assets/associations/tpcof.jpg')"></b-carousel-slide>
          </b-carousel>
        </div>
        <div class="col-12 pt-2">
          <p v-html="data.json['paragraph2']"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  props: {

  },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'Qualifications');
      return {
        json: cJson,
        global: this.$webData.global
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 .container {
   .row {
     padding-top: 0;
   }
 }
</style>
